<template>
  <el-card shadow="never">
    <h3 class="title text-left">{{ $t("userProfile.student.studentInfo") }}</h3>
    <template v-if="editDialog">
      <EditDialogStudent
        :editDialog="editDialog"
        :profile="profile"
        @updateDialog="updateDialog"
      ></EditDialogStudent>
    </template>

    <!-- 學生資料 -->
    <el-table :data="studentTableData" :show-header="false">
      <el-table-column prop="name" width="auto">
        <template slot-scope="scope">{{ $t(scope.row.name) }}</template>
      </el-table-column>

      <el-table-column prop="data" width="auto">
        <template slot-scope="scope">
          <template v-if="scope.row.parent">
            <div v-for="(item, key) in scope.row.data" :key="key">
              <slot>{{ `${item.first_name} ${item.last_name}` }}</slot>
            </div>
          </template>
          <template v-else>
            <slot>{{ scope.row.data }}</slot>
          </template>
        </template>
      </el-table-column>
      <el-table-column width="auto"></el-table-column>
    </el-table>
  </el-card>
</template>

<script>
import { mapGetters } from "vuex";
import EditDialogStudent from "@/components/userProfile/EditDialogStudent";
export default {
  components: {
    EditDialogStudent
  },
  mixins: [],
  props: [],
  data() {
    return {
      profile: {},
      studentTableData: [
        {
          name: "userProfile.student.school",
          data: "",
          edit: true,
          propertyName: "high_school"
        },
        {
          name: "userProfile.student.gradeLevel",
          data: "",
          edit: true,
          propertyName: "high_school_graduation_year"
        }
        // {
        //   name: "userProfile.student.classTaken",
        //   data: "",
        //   edit: true,
        //   propertyName: "taken_class_where"
        // },
        // {
        //   name: "userProfile.student.comments",
        //   data: "",
        //   edit: true,
        //   propertyName: "taken_class_comments"
        // },
        // {
        //   name: "userProfile.student.prefLang",
        //   data: "",
        //   edit: true,
        //   propertyName: "lang_pref"
        // },
        // {
        //   name: "userProfile.student.knowUsFrom",
        //   data: "",
        //   edit: true,
        //   propertyName: "know_us"
        // },
        // {
        //   name: "userProfile.student.referral",
        //   data: "",
        //   edit: true,
        //   propertyName: "recommendation_info"
        // },
        // {
        //   name: "userProfile.student.notes",
        //   data: "",
        //   edit: false
        // },
        // {
        //   name: "userProfile.student.parent",
        //   data: "",
        //   edit: false,
        //   propertyName: "parents",
        //   parent: true
        // }
      ],
      editDialog: false
    };
  },
  mounted() {
    this.profile = this.getUserData.student;
    this.assignData();
  },
  methods: {
    updateDialog(status) {
      this.editDialog = false;
      this.editDialogTest = false;
      if (status === "submit") {
        this.profile = this.getStudentDataAfterPatch.student;
        this.assignData();
      } else {
        this.profile = this.getUserData.student;
        this.assignData();
      }
    },
    assignData() {
      this.studentTableData[0].data = this.profile.high_school;
      this.studentTableData[1].data = this.profile.high_school_graduation_year;
      // this.studentTableData[2].data = this.profile.taken_class_where;
      // this.studentTableData[3].data = this.profile.taken_class_comments;
      // this.studentTableData[4].data = this.profile.lang_pref;
      // this.studentTableData[5].data = this.profile.know_us;
      // this.studentTableData[6].data = this.profile.recommendation_info;
      // this.studentTableData[8].data = this.profile.parents;
    }
  },
  computed: {
    ...mapGetters("profile", ["getUserData", "getStudentDataAfterPatch"])
  }
};
</script>

<style scoped>
.el-card {
  background-color: #f2f3f5;
}
.btn-edit:focus {
  outline: none;
}
.title {
  color: #494949;
  font-size: 20px;
  border: 0;
  padding: 0;
  border-left: 4px solid var(--themeColor);
  padding-left: 15px;
  height: 30px;
  line-height: 30px;
  margin-bottom: 20px;
}
</style>

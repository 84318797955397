<template>
  <div class="profile">
    <div>
      <div
        v-if="!emailIsConfirmed && CompanyName === 'TestAdmit'"
        class="box-card"
      >
        <el-alert
          type="warning"
          show-icon
          :closable="false"
          style="text-align:left"
        >
          <b style="margin-right:20px">{{ $t("message.confirm_email") }}</b>
          <el-button type="success" size="small" @click="sendVerifyEmail">
            {{ $t("message.confirm_your_email") }}
          </el-button>
        </el-alert>
      </div>
      <div class="box-card">
        <div class="userName">
          <PageTitle>{{ formattedUserName }}</PageTitle>
          <hr />
        </div>

        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            :label="$t('userProfile.basicInfo')"
            name="info"
            v-if="dispatchFinished"
          >
            <BasicInfo></BasicInfo>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('userProfile.studentInfo')"
            name="student"
            v-if="dispatchFinished && roles.includes('student')"
          >
            <StudentInfo style="margin:20px 0"></StudentInfo>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('userProfile.Testing')"
            name="Testing"
            v-if="dispatchFinished && roles.includes('student')"
          >
            <Testing v-if="showTesting" style="margin:20px 0"></Testing>
          </el-tab-pane>
          <template v-if="CompanyName === 'TestAdmit'">
            <el-tab-pane
              :label="$t('userProfile.T-Coins')"
              name="T-Coins"
              v-if="dispatchFinished && roles.includes('student')"
            >
              <Balance
                style="margin:20px 0"
                :point="getProfile.point"
                :showLink="true"
              />
              <Trades />
              <UserTransactions />
            </el-tab-pane>
            <el-tab-pane
              :label="$t('header.plans')"
              name="plans"
              v-if="dispatchFinished && roles.includes('student')"
            >
              <div class="container">
                <Subscription
                  :isPro="getIsPro"
                  :proTimeLimited="getProTimeLimited"
                ></Subscription>
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="Setting"
              name="setting"
              v-if="dispatchFinished && roles.includes('super-admin')"
            >
              <el-form align="left">
                <el-form-item label="Free plan display TPO1 - TPO66">
                  <el-switch
                    active-text="Yes"
                    inactive-text="No"
                    :active-value="1"
                    :inactive-value="0"
                    :value="tpo1To66TestIsShowForFree"
                    @input="switchTpo1To66TestIsShowForFree"
                  />
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </template>
        </el-tabs>
        <div style="margin-top:20px" v-if="CompanyName === 'TestAdmit'">
          <InviteCard />
        </div>
      </div>
      <div style="margin:20px auto" v-if="CompanyName === 'TestAdmit'">
        <el-button type="info" @click="deleteUser">
          {{ $t("button.deleteMyAccount") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { instant } from "@ivy-way/material";
import { user } from "@ivy-way/material";
import User from "@/apis/user.js";
import PageTitle from "@/components/PageTitle.vue";
import BasicInfo from "@/components/userProfile/BasicInfo";
import StudentInfo from "@/components/userProfile/StudentInfo";
import Testing from "@/components/userProfile/Testing";
import Subscription from "@/components/userProfile/Subscription";
import Balance from "@/views/toefl/transcript/components/balance";
import InviteCard from "@/components/InviteCard";
import Trades from "@/views/toefl/views/Trades.vue";
import UserTransactions from "@/views/toefl/views/UserTransactions.vue";

export default {
  metaInfo() {
    return {
      title: "Profile - " + this.CompanyName
    };
  },
  components: {
    PageTitle,
    BasicInfo,
    StudentInfo,
    Testing,
    Balance,
    InviteCard,
    Trades,
    UserTransactions,
    Subscription
  },
  computed: {
    ...mapState("profile", ["userRoleProfile"]),
    ...mapGetters("user", ["getProfile", "getIsPro", "getProTimeLimited"]),
    formattedUserName() {
      return this.userMethods.displayName(
        this.userRoleProfile.basic_info?.first_name,
        this.userRoleProfile.basic_info?.last_name
      );
    },
    userMethods() {
      return user;
    },
    instant() {
      return instant;
    }
  },
  data() {
    return {
      showTesting: false,
      activeName: null,
      roles: [],
      emailIsConfirmed: false,
      dispatchFinished: false,
      tpo1To66TestIsShowForFree: 0
    };
  },
  async mounted() {
    if (this.$route.query.username) {
      this.$router.push({
        path: `userProfile?username=${this.$route.query.username}`
      });
    }
    if (this.$route.query.tab) {
      this.activeName = this.$route.query.tab;
    } else {
      this.activeName = "info";
    }
    await this.initialProfile();
    const { settings } = await User.getSetting();
    this.tpo1To66TestIsShowForFree = settings?.tpo_1_66_test_is_show_for_free;
  },
  methods: {
    handleClick(tab) {
      if (tab.name === "Testing") {
        this.showTesting = true;
      }
    },
    async initialProfile() {
      this.dispatchFinished = false;
      await this.$store.dispatch("profile/userRoleProfile");
      this.roles = this.userRoleProfile.basic_info.all_roles;
      this.emailIsConfirmed = Boolean(
        this.userRoleProfile.basic_info.is_active
      );
      this.dispatchFinished = true;
    },
    async sendVerifyEmail() {
      await User.sendVerifyEmail();
      this.$message({
        message: this.$t("message.emailSent"),
        type: "success"
      });
    },
    async switchTpo1To66TestIsShowForFree(tpo1To66TestIsShowForFree) {
      await User.patchSetting({
        tpo_1_66_test_is_show_for_free: tpo1To66TestIsShowForFree
      });
      this.tpo1To66TestIsShowForFree = tpo1To66TestIsShowForFree;
    },
    deleteUser() {
      this.$confirm(
        this.$t("userProfile.dialog.continueDeleteAccount"),
        this.$t("userProfile.dialog.warning"),
        {
          confirmButtonText: this.$t(
            "userProfile.dialog.sureContinueDeleteAccount"
          ),
          cancelButtonText: this.$t("userProfile.dialog.donNotDeleteAccount"),
          type: "warning"
        }
      ).then(async () => {
        await User.deleteUser(this.userRoleProfile.basic_info.id);
        this.$message({
          type: "success",
          message: this.$t("message.delete_success")
        });
      });
    }
  }
};
</script>

<style scoped>
/* ::v-deep .el-card {
  background-color: #f2f3f5;
} */
.userName {
  width: 100%;
  text-align: left;
}

.primaryColor {
  color: var(--themeColor);
}
.box-card {
  display: inline-block;
  width: 90%;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}
.profile {
  text-align: center;
  padding-top: 20px;
}
@media screen and (max-width: 400px) {
  ::v-deep .el-card__body {
    padding: 10px;
  }
  .header .card h2 {
    line-height: 30px;
  }
  ::v-deep .el-button.el-button--success {
    width: 100%;
  }
  ::v-deep .is-underline {
    width: 100%;
  }
  ::v-deep .el-link--inner {
    width: 100%;
  }
  .profile {
    padding-left: 15px;
    padding-right: 15px;
  }
  .box-card {
    display: inline-block;
    width: 100%;
  }
}
::v-deep .el-tabs__nav-scroll {
  overflow: auto;
}
</style>

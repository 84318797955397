<template>
  <el-card class="balance-card" shadow="never">
    <div slot="header" class="clearfix text-center">
      <div style="position:relative">
        <div>
          <i class="fas fa-donate"></i>
          {{ $t("toefl.Invitation Link") }}
        </div>
        <div class="coin-button">
          <span style="padding: 3px 0;margin-right: 10px" type="text">
            {{ $t("toefl.Members invited") }}
            <span :class="ReferralUsers < 2 ? 'text-danger' : 'text-success'">{{
              ReferralUsers
            }}</span>
            / 2
          </span>
          <el-button
            :type="ReferralUsers > 1 && !has_exchanged ? 'success' : 'info'"
            :disabled="!(ReferralUsers > 1 && !has_exchanged)"
            @click="exchangePoint"
          >
            <span v-if="has_exchanged">
              {{ $t("toefl.Get Success") }}
            </span>
            <span v-else>
              {{ $t("toefl.Collect 8 Free T-Coins") }}
            </span>
          </el-button>
        </div>
      </div>
    </div>
    <div class="body">
      <div class="info text-center">
        <span type="info">
          {{ $t("toefl.Share Link") }}
        </span>
      </div>
      <div class="text-center" v-if="referral_code === null">
        <el-button type="success" @click="createReferralCode">
          {{ $t("toefl.Show My Invitation Link") }}
        </el-button>
      </div>
      <div class="text-center" v-else>
        <div class="referral-code">
          <a>
            <span>{{ link }}</span>
          </a>
        </div>
        <el-button type="success" @click="copyLink">
          {{ $t("toefl.Copy Link") }}
        </el-button>
      </div>
    </div>
  </el-card>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import User from "@/apis/user.js";
import baseDomainPath from "@/common/baseDomainPath";

export default {
  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      referral_code: null,
      ReferralUsers: 0,
      has_exchanged: false
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    link() {
      return `${baseDomainPath}register?referral_code=${this.referral_code}`;
    }
  },
  watch: {},

  mounted() {
    this.referral_code = this.getProfile.referral_code;
    this.getReferralUsed();
  },

  methods: {
    async createReferralCode() {
      const res = await User.getReferralCode();
      this.referral_code = res.referral_code;
    },
    async getReferralUsed() {
      const res = await User.getReferralUsed();
      this.ReferralUsers = res.referral_code_used_count;
      this.has_exchanged = res.has_exchanged;
    },
    async exchangePoint() {
      await User.exchangePoint();
      this.$message({
        message: "Success!",
        type: "success"
      });
      this.$router.go(0);
    },
    copyLink() {
      var oInput = document.createElement("input");
      oInput.value = this.link;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.$message({
        message: "Copy successfully! Go and share it with your friends to get T-Coins!",
        type: "success"
      });
    }
  }
};
</script>

<style scoped>
.balance-card {
  /* height: 100%; */
}
.referral-code span {
  display: inline-block;
  border: 1px solid #ebeef5;
  padding: 10px 20px;
  margin-bottom: 10px;
  font-size: 18px;
}
.info {
  margin-bottom: 20px;
  font-size: 14px;
}
.coin-button {
  position: absolute;
  right: 0;
  top: -8px;
}

@media screen and (max-width: 1020px) {
  .coin-button {
    margin-top: 16px;
    position: static;
  }
}

@media screen and (max-width: 400px) {
  ::v-deep .el-card__header {
    height: 150px;
  }
  .info {
    font-size: 16px;
    line-height: 20px;
  }
}
</style>

<template>
  <el-select
    style="width:100%"
    :placeholder="$t('enrollment.step2.student.grade.placeholder')"
    :value="value"
    @change="changeValue"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "YearList",
  props: {
    value: {
      type: Number,
      default: null
    }
  },
  async created() {
    this.options = this.yearList();
  },
  data() {
    return {
      options: [],
      selected: null
    };
  },
  methods: {
    yearList() {
      let yearList = [];
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth();
      if (month >= 7) {
        year++;
      }
      yearList.push({
        value: year - 1,
        label: "Graduating on or before " + (year - 1)
      });
      for (let i = 12; i >= 1; i--) {
        yearList.push({
          value: year,
          label: i + "th Grade (Graduating in " + year + ")"
        });
        year++;
      }
      return yearList;
    },
    changeValue(value) {
      this.$emit("onYearChange", value);
    }
  }
};
</script>
